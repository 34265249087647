import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    padding: 20px;

    width: 100%;

    gap: 150px;
`;

export const CopyRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0;
    left: 0;

    background-color: #000000;

    width: 100%;

    padding: 15px;


    gap: 5px;


    & div {
        font-size: 9px;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 12px;
        width: 12px;

        border-radius: 100%;

        padding: 5px;

        border: 1px solid #ffffff;
    }
`;

export const Name = styled.p`
     color: #ffffff;
`;

export const SocialLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 25px;

    background-color: white;

    border-radius: 8px 8px 0 0;


    padding: 10px;
    margin: 50px 0 100px;
`;