import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;

    gap: 10px;

    width: 100%;

    margin-top: 100px;

    padding: 10px;

    background-color: #0c1821;

    color: #ffffff;
`;


export const Header = styled.div`
    margin: 20px 0 50px;

    font-size: 3rem;

    color: #ffffff;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 2rem;
    }

    @media(width <= 768px) {
        font-size: 1.7rem;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 800px;

    border: 1px solid white;

    border-radius: 5px;

    padding: 20px;

    @media screen and (min-width: 0) and (max-width: 902px) {
        width: 600px;
    }

    @media(width <= 768px) {
        width: 100%;
    }

`;

export const ContactText = styled.div`
margin: 0 0 -190px;
width: 700px;

@media(width <= 768px) {
    width: 100%;
    
    text-align: center;
    width: 100%;

    font-size: 0.8rem;
    order: 1;

    margin: unset;

    ${Header} {
        font-size: 2rem;
        margin-top: -50px;
    }
}
`;

export const Label = styled.label`
    font-size: 1.5rem;
`;

export const MessageBox = styled.textarea`
    padding: 10px;

    width: 500px;
    min-height: 100px;

    border-radius: 5px;
    border: none;

    outline: none;

    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    margin-bottom: 40px;

    resize: none;

    @media screen and (min-width: 680px) and (max-width: 902px) {
        width: 450px;
    }

    @media(width <= 768px) {
        width: 100%;
    }
`;

export const Input = styled.input`
    padding: 10px;

    width: 500px;

    border-radius: 2px;
    border: none;

    outline: none;

    font-size: 1.2rem;

    margin-bottom: 20px;

    @media screen and (min-width: 0) and (max-width: 902px) {
        width: 450px;
    }

    @media(width <= 768px) {
        width: 100%;
    }
`;

export const SubmitButton = styled.input`
    color: #ffffff;
    background-color: #1b2a41;

    width: 500px;
    padding: 15px;
    
    outline: none;
    border: none;

    border-radius: 2px;

    transition: ease-in-out 250ms;

    cursor: pointer;

    @media screen and (min-width: 0) and (max-width: 902px) {
        width: 450px;
    }

    @media(width <= 768px) {
        width: 100%;
    }

    &:hover {
        background-color: #12212a;
    }

`;

export const P = styled.p`
    font-size: 2rem;

    color: #ffffff;

    margin-top: 50px;

    text-align: center;

    @media(width <= 768px) {
        font-size: 1.7rem;
    }
`;