import React from "react";

import { Container, CopyRight, Name, SocialLinks } from "./FooterStyles";
import MediaImgs from "../LandingPage/media";

export const Footer = () => {
    return(
        <Container>
            <SocialLinks>
                <MediaImgs />
            </SocialLinks>
            
            <CopyRight>
                <div>C</div>
                <Name>2024 Ray Mcmillan Gumbo. All rights reserved.</Name>
            </CopyRight>
        </Container>
    )
}