import styled from "styled-components";

export const LogoImg = styled.div `
    font-size: 2rem;
    width: 100%;

    color: white;

    

    @media screen and (min-width: 760px) and (max-width: 1290px) {
        font-size: 1.5rem;
    }
    
    
    @media(width <= 768px) {
        font-size: 1.2rem;
    }

    `;

export const Navbar = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    position: fixed;
    top: 0;
    left: 0;

    padding: 30px 150px;    

    background-color: #000000;

    z-index: 5;


    @media screen and (min-width: 760px) and (max-width: 1290px) {
        padding: 20px 40px;
    }

    @media(width <= 768px) {
        padding: 15px 30px;
    }

`;


export const UList = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    list-style: none;

    


    @media(width <= 768px) {
        flex-direction: column;

        
    }
   
`;

export const ListElement = styled.li`
font-family: Verdana, Geneva, Tahoma, sans-serif;

color: #ffffff;
padding: 5px;


transition: ease-in-out 250ms;

cursor: pointer;

&:hover {
     background-color: #324a5f;
    
     border-radius: 3px;
}

`;


export const Menu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

    @media(width <= 768px) {
        color: white;

        position: absolute;
        top: 0;
        left: 0;
        
        width: 100vw;
        height: 100vh;
        
        background-color: rgba(0,0,0,0.93);
    }
`;

export const Open = styled.div`
    padding: 8px;

    height: 32px;
    width: 32px;

    border: 1px solid white;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
        width: 18px;
        height: 1px;
        background-color: white;

        margin-bottom: 3px;

    }
`;


const Line = styled.div`
    position: absolute;
    width: 18px;
    height: 1.5px;

    background-color: white;
`;

export const Line1 = styled(Line)`
    transform: rotate(45deg);
`;

export const Line2 = styled(Line)`

    transform: rotate(-45deg);
`;



export const X = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 32px;
    width: 32px;

    padding: 8px;

    border: 1px solid white;
    border-radius: 4px;

`;

export const HamburgerMenu = styled.div`
display: flex;
align-items: center;
justify-content: center;

padding: 8px;

transition: ease-in-out 250ms;

position: absolute;
right: 20px;


@media(width > 768px) {
    
    display: none;
}
`;