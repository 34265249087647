import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    gap: 40px 0;

    padding: 20px;


    transition: ease-in-out 250ms;

    background-color: #ccc9dc;

    border-radius: 2px;
`;

export const Header = styled.div`
    margin: 20px auto;
    font-size: 3rem;

    color: #000000;
    font-weight: bolder;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 2rem;
    }

    @media(width <= 768px) {
        font-size: 1.7rem;
    }
`;



export const FrontEnd = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    gap: 50px;

    @media(width <= 768px) {
        flex-direction: column;
    }
`;


export const Network = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    gap: 50px;

    margin-bottom: 20px;

    @media(width <= 768px) {
        flex-direction: column;
    }
`;




