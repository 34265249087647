import { Container, MediaBox, Page, Header, SubHeader, InfoParagraph, Button} from "./landingPageStyles";

import MediaImgs from "./media";

import { RefContext } from "../../App";
import { useContext, useEffect, useRef } from "react";


function LandingPage({id, ref}) {
    const refs= useContext(RefContext);

    const goToContact =  () => {
        const ref = refs[`section6Ref`];
        const y = ref.offsetTop;

        window.scrollTo({
            top: y,
            behavior: "smooth",
        });
    };
    return (
        <Container>
            <MediaBox>
                <MediaImgs />
            </MediaBox>
            <Page>
                <Header>CRAFTING SOFTWARE TECHNOLOGIES</Header>
                <SubHeader>Software Engineer, Front End & Back End.</SubHeader>
                <InfoParagraph>
                   Web, Cybersecurity, Data Science, UI/UX Design & Software Development. Fluent in
                   multiple programming languages and paradigms. Want me to build you, your dream website? What are you waiting for? 
                </InfoParagraph>
                <Button onClick={() => goToContact()}>Get in touch</Button>
            </Page>
        </Container>
    )
}

export default LandingPage;