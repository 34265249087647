import { MediaImg, MediaLink } from "./landingPageStyles";
import LinkedIn from "../../images/icons8-linkedin-48.png";
import GitHub from "../../images/icons8-github-64.png";
import HashNode from "../../images/icons8-hashnode-48.png";
import X from "../../images/icons8-twitterx-50 (1).png";
import Discord from "../../images/icons8-discord-48 (1).png"


const MediaImgages = [
    {
        "id": 1,
        "image": LinkedIn,
        "link": "https://www.linkedin.com/in/ray-mcmillan-gumbo-7b9208250/",
        "alt": "LinkedIn"
    }, 

    {
        "id": 2,
        "image": HashNode,
        "link": "https://hashnode.com/@ryoKun",
        "alt": "HashNode"
    },

    {
        "id": 3,
        "image": X,
        "link": "https://twitter.com/RyoGenxx",
        "alt": "X"
    },

    {
        "id": 4,
        "image": GitHub,
        "link": "https://github.com/Raymacmillan",
        "alt": "GitHub"
    },

    {
        "id": 5,
        "image": Discord,
        "link": "https://discord.com/channels/@ryokun0339",
        "alt": "Discord"
    }

    
]

function MediaImgs() {
    return (
        <>
        {MediaImgages && MediaImgages.map((img) => {
            return(
                <MediaLink href={img.link}>
                <MediaImg key={img.id} src={img.image} alt={img.alt}/>
                </MediaLink>
            )
        })}
        </>
    )
}

export default MediaImgs;
