import styled from "styled-components";

export const MyProjects = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 20px;

    @media(width <= 768px) {
        justify-content: center;
    }
`;

export const Header = styled.div`
    margin-top: 46px;
    font-size: 3rem;

    color: #000000;
    font-weight: bolder;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 2rem;
    }

    @media(width <= 768px) {
        font-size: 1.7rem;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;

    margin-bottom: 30px;

    @media(width <= 768px) {
      gap: 15px;
    }
`;

export const Source = styled.a`
    text-decoration: none; 
    
    cursor: pointer;

    margin-top: 48px;
`;

export const GithubImg = styled.img`
    width: 50px;
    height: 50px;

    transition: ease-in-out 300ms;

    &:hover {
        transform: scale(1.1);
    }

    @media(width <= 768px) {
        width: 30px;
        height: 30px;
    }
`;