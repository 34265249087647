import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 20px;

    background-color: #37393c;
`;

export const SubHeader = styled.div`
    margin-top: 40px;
    font-size: 3rem;

    color: #ffffff;
    font-weight: bolder;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 2rem;
    }

    @media(width <= 768px) {
        font-size: 1.7rem;
    }
`;


export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;

    margin: 40px 0 40px;

    gap: 10px;

    & > p {
        color: white;
        font-weight: bolder;

        font-size: 0.8rem;
    }
`;

export const Image = styled.img`
    width: 900px;
    height: 550px;

    border-radius: 5px;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        width: 750px;
        height: fit-content;
    }

    @media(width <= 768px) {
        width: 100%;
        height: fit-content;
    }

    
`;

export const PrevButton = styled.button`
    position: absolute;
    width: 80px;
    height: 98%;

    top: 0;
    left: 0;

    background-color: transparent;


    border: none;
    outline: none;

    cursor: pointer;


    transition: ease-in-out 250ms;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

`;

export const NextButton = styled.button`
    position: absolute;
    width: 80px;
    height: 98%;

    top: 0;
    right: 0;

    background-color: transparent;

    border: none;
    outline: none;

    cursor: pointer;


    transition: ease-in-out 250ms;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 200px;

    @media(width <= 768px) {
        gap: 80px;
    }
`;
