import { useRef, useEffect, useContext } from "react";
import { RefContext } from "./App";

function Section({id, children}) {
    const sectionRef = useRef(null);
    const {setSection2Ref, setSection3Ref, setSection4Ref, setSection5Ref, setSection6Ref} = useContext(RefContext);

    useEffect(() => {
        if(sectionRef && sectionRef.current) {
          if(id === 2) {
            setSection2Ref(sectionRef.current);
          }
    
          if(id === 3) {
            setSection3Ref(sectionRef.current);
          }
    
          if(id === 4) {
            setSection4Ref(sectionRef.current);
          }
    
          if(id === 5) {
            setSection5Ref(sectionRef.current);
          }
    
          if(id === 6) {
            setSection6Ref(sectionRef.current);
          }
          
        }
      }, [sectionRef])

      return(
        <div ref={sectionRef}>
            {children}
        </div>
      )
}

export default Section;