import React, { useRef, useState } from 'react';

import { Container, Header, Form, Label, Input, MessageBox, SubmitButton, P } from "./ContactStyles";
import emailjs from '@emailjs/browser';
import { Footer } from './Footer';

export const ContactUs = ({id, ref}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useRef();

  const clearForm = () => {
    form.current.reset();
  }

  const sendEmail = async (e) => {
    e.preventDefault();

    try {

    const response = await emailjs
      .sendForm('service_nsh7g45', 'template_aietpaj', form.current, {
        publicKey: 'tDFbTBdWN43Wy6P82',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsSubmitted(true);
        },
      );
      clearForm();
    } catch (error) {
      console.log("FAILED...", error.text);
    }
  };

  return (
    <Container>
    <Header>Contact </Header>
    <Form ref={form} onSubmit={sendEmail}>
      <Label>Name</Label>
      <Input type="text" name="user_name" />
      <Label>Email</Label>
      <Input type="email" name="user_email" />
      <Label>Message</Label>
      <MessageBox name="message" />
      <SubmitButton type="submit" value="Send" onSubmit={sendEmail} />
    </Form>
    {isSubmitted && <p>Thank you for your message!</p>}
    <P>Get in touch on other platforms</P>
    <Footer />
    </Container>
  );
};