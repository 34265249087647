import styled from "styled-components";


export const Container = styled.a`
    border-radius: 3px;

    width: calc(33.33% - 20px);
    margin-bottom: 50px;

    color: inherit;

    text-align: center;

    text-decoration: none;

    background-color: #0c1821;

    border-radius: 20px;

    max-width: 480px;

    max-height: 520px;

    padding: 20px;

    @media(max-width: 1200px) {
        width: calc(50% - 20px);
    }

    @media (width <= 768px) {
        width: 100%;
    }

    color: #ffffff;
`;

export const ImageProject = styled.img`
    width: 100%;
    height: 300px;
`;

export const ProjectTitle = styled.h1`
    margin: 10px 0 20px;
    font-size: 1.6rem;
`;


export const TechUsed = styled.h3`

    padding: 5px 10px;

    border-radius: 15px;

    background-color: rgba(0,0,0,0.9);

    width: fit-content;


    @media (width <= 768px) {
        font-size: 1rem;
    }
`;

export const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-wrap: wrap;


    gap: 15px;
`;

export const SourCode = styled.a`
    text-decoration: none;
`;