import { Container, ImageProject, ProjectTitle, TechUsed, BoxContainer, SourCode } from "./ProjectStyles";

function Project({ linkRef,href, ImgURL, title, tech, link, alt }) {
    return (
        <Container href={href}>
            <ImageProject src={ImgURL} alt={alt} />
            <ProjectTitle>{title}</ProjectTitle>
            <BoxContainer>
                {tech?.length && tech.map((skill) => {
                    return(
                        <TechUsed>{skill}</TechUsed>
                    )
                })}
            </BoxContainer>
            <SourCode linkRef={linkRef}></SourCode>
        </Container>
    )
}

export default Project;

