import './App.css';
import { useContext, createContext, useState, useRef, useEffect } from 'react';

import JavaScript from "./images/REACT (1).png";
import HTMLCSS from "./images/REACT (1).png";
import Java from "./images/REACT (2).png";
import Python from "./images/REACT (3).png";
import Other from "./images/REACT (4).png";

import Nav from "./Components/NavBar/Nav";
import Section from './Section';
import Expertise from "./Components/Expertise/Expertise";
import LandingPage from "./Components/LandingPage/landingPage";
import Certifications from './Components/Certification/Certification';
import Projects from './Components/Projects/Projects';
import { ContactUs } from './Components/Contact/Contact';


export const RefContext = createContext(null);

function App() {
  const [section2Ref, setSection2Ref] = useState(null);
  const [section3Ref, setSection3Ref] = useState(null);
  const [section4Ref, setSection4Ref] = useState(null);
  const [section5Ref, setSection5Ref] = useState(null);
  const [section6Ref, setSection6Ref] = useState(null);

  const Skills = [
    {
      "id": 1,
      "src": HTMLCSS,
      "alt": "Java"
    },
    {
      "id": 2,
      "src": JavaScript,
      "alt": "Java"
    },
    {
      "id": 3,
      "src": Java,
      "alt": "Java"
    },
    {
      "id": 4,
      "src": Python,
      "alt": "Java"
    },
    {
      "id": 5,
      "src": Other,
      "alt": "Other-Skills"
    }
  ]

  return (
    <RefContext.Provider value={{ section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, setSection2Ref, setSection3Ref, setSection4Ref, setSection5Ref, setSection6Ref }}>
      <Nav />
      <Section id={2} >
        <LandingPage />
      </Section>
      <Section id={3}>
        <Expertise config={Skills} />
      </Section>
      <Section id={4}>
        <Certifications />
      </Section>
      <Section id={5}>
       <Projects />
      </Section>
      <Section id={6}>
        <ContactUs />
      </Section>
    </RefContext.Provider>
  );
}

export default App;
