import Logo from "../../images/RMG.png";
import { Navbar, LogoImg, ThemeToggle, UList, ListElement, Menu, X, Line1, Line2, Open, HamburgerMenu } from "./NavStyles";

import { RefContext } from "../../App";
import { useState, useContext } from "react";

import useGetWindowSize from "./useGetWindowSize";

function Nav() {
    const refs = useContext(RefContext);

    const [isOpen, setIsOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const screenSize = useGetWindowSize();
   
    const goTo =  (sectionNum) => {
        const ref = refs[`section${sectionNum}Ref`];
        const y = ref.offsetTop;

        window.scrollTo({
            top: y,
            behavior: "smooth",
        });
    };

    const handleIsOpen = () => {
        setIsOpen(!isOpen);
        setShowMenu(!showMenu);
    }


    return (
        <Navbar>
            <LogoImg>RMG</LogoImg>
            {(showMenu || screenSize > 768) && <Menu>
                <UList>
                    <ListElement id="home" onClick={() => goTo(2)}>Home</ListElement>
                    <ListElement id="expertise" onClick={() => goTo(3)}>Expertise</ListElement>
                    <ListElement id="certifications" onClick={() => goTo(4)}>Certifications</ListElement>
                    <ListElement id="projects" onClick={() => goTo(5)}>Projects</ListElement>
                    <ListElement id="contacts" onClick={() => goTo(6)}>Contact</ListElement>
                </UList>
            </Menu>}
            <HamburgerMenu onClick={handleIsOpen}>
                {!isOpen && <Open>
                    <div></div>
                    <div></div>
                    <div></div>
                    </Open>}
                {isOpen && <X>
                    <Line1 />
                    <Line2 />
                    </X>}    
            </HamburgerMenu>
        </Navbar>
    )
}

export default Nav;