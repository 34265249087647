import { Container, Header, FrontEnd, JavaScript, Designer, Network } from "./CertificationsStyles";
import JCertificate from "../../images/javascript.png";
import CCertificate from "../../images/cybersecurity.png";
import ECertificate from "../../images/EHacker.png";
import Certificate from "./Certificate";
import UCertificate from "../../images/UX.png";
import DCertificate from "../../images/DataScience.png";


function Certifications({id, ref}) {
    return (
        <Container>
            <Header>Certificates</Header>
            <FrontEnd>
                <Certificate imageUrl={JCertificate} />
                <Certificate imageUrl={UCertificate} />
                <Certificate imageUrl={DCertificate} />
            </FrontEnd>
            <Network>
                <Certificate imageUrl={CCertificate} />
                <Certificate imageUrl={ECertificate} />
            </Network>
          
        </Container>
    )
}

export default Certifications;