import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 10px 20px;

    height: 100vh;

    gap: 40px;


    text-align: center;


    @media screen and (min-width: 769px) and (max-width: 1033px) {
        gap: 40px;
    }

    @media(width <= 768px) {
        justify-content: center;
        margin-top: 50px;
    }


`;

export const MediaBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 10px;

    width: 95px;
    height: 270px;

    padding: 20px 15px;

    border-radius: 0 5px 5px 0;

    background-color: #ffffff;

    margin-left: -20px;

    color: white;

    box-shadow: 0 0 5px rgba(0,0,0,0.7);

    @media(width <= 1032px) {
        display: none;
    }
`;

export const MediaImg = styled.img`
    width: 40px;
    height: 40px;


    cursor: pointer;

    transition: ease-in-out 250ms;

    &:hover {
        transform: scale(1.1);
    }
`;

export const MediaLink = styled.a`
    text-decoration: inherit;
    color: inherit;
`;

export const Information = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 20px;
`;

export const Location = styled.p`
    width: 80px;
`;

export const GlobeImg = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px;

    width: 60px;
    height: 60px;

    border-radius: 100%;

    background-color: white;
`;

export const Globe = styled.img`
    width: 35px;
    height: 35px;
`;

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Header = styled.div`
    font-size: 3.8rem;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 3rem;
    }

    @media(width <= 768px) {
        font-size: 2.5rem;
    }

    @media(width <= 391px) {
        font-size: 1.8rem;
    }
`;

export const SubHeader = styled.div`
    font-size: 2.5rem;

    margin: 10px 0;

    padding: 15px;

    @media screen and (min-width: 769px) and (max-width: 1033px) {
        font-size: 1.8rem;
    }

    @media(width <= 768px) {
        font-size: 1.5rem;
    }

    @media(width <= 391px) {
        font-size: 0.8rem;
    }
`;

export const InfoParagraph = styled.p`
    border-radius: 12px;

    text-align: justify;

    width: 70%;


    @media screen and (min-width: 769px) and (max-width: 1033px) {
        width: 100%;
    }

    @media(width <= 768px) {
        text-align: center;

        width: 100%;
    }
`;

export const Button = styled.button`
    margin: 20px 0;
    padding: 20px;

    
    background-color: #1b2a41;
    color: white;

    border-radius: 2px;

    border: none;
    outline: none;

    cursor: pointer;

    width: 150px;

    transition: ease-in-out 250ms;

    &:hover {
        background-color: #12212a;
    }
`;