import React from "react";
import { MyProjects, Header, HeaderContainer, Source, GithubImg } from "./ProjectsStyles";
import GitHubLogo from "../../images/icons8-github-64.png";
import Calculator from "../../images/Calculator.png";
import Etch from "../../images/etch.png";
import Library from "../../images/library.png";
import Odin from "../../images/Odin.png";
import PreviewCard from "../../images/previewCard.png";
import Card from "../../images/Card.png";
import Advice from "../../images/advice.png";
import Testimonies from "../../images/testimonies.png";
import DropDown from "../../images/dropDown.png";
import Notifications from "../../images/notifications.png";
import Pricing from "../../images/pricing.png";
import NewHomePage from "../../images/newsHomepage.png";
import Temperature from "../../images/temp.png";
import Interactive from "../../images/interactive.png";
import API from "../../images/API.jpg";


import Project from "./Project";

const codingProjects = [
    {
        "id": 13,
        "ImgURL": API,
        "title": "Restful API(CRUD)",
        "tech": ["Java"],
        "liveLink": "https://github.com/Raymacmillan/CRUD-API",
        "link": "https://github.com/Raymacmillan/CRUD-API",
        "alt": "API"
    },
    {
        "id": 9,
        "ImgURL": Notifications,
        "title": "Notification Page",
        "tech": ["React","CSS"],
        "liveLink": "https://notification-page-tau.vercel.app/",
        "link": "https://github.com/Raymacmillan/Notification-Page",
        "alt": "Notification"
    },
    {
        "id": 11,
        "ImgURL": NewHomePage,
        "title": "News Homepage",
        "tech": ["React","CSS"],
        "liveLink": "https://news-homepage-qvx46n7lm-raymacmillan.vercel.app/",
        "link": "https://github.com/Raymacmillan/News-Homepage",
        "alt": "NewsHomepage"
    },
    {
        "id": 2,
        "ImgURL": Odin,
        "title": "Sign up Form",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Sign-up-Form/",
        "link": "https://github.com/Raymacmillan/Sign-up-Form",
        "alt": "Odin"
    },
    {
        "id": 0,
        "ImgURL": Calculator,
        "title": "Calculator",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Calculator/",
        "link": "https://github.com/Raymacmillan/Calculator",
        "alt": "Calculator"
    },
    {
        "id": 1,
        "ImgURL": Etch,
        "title": "Etch a Sketch",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Etch-a-Sketch/",
        "link": "https://github.com/Raymacmillan/Etch-a-Sketch",
        "alt": "Etch-a-sketch"
    },
    {
        "id": 3,
        "ImgURL": Library,
        "title": "Library",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Library/",
        "link": "https://github.com/Raymacmillan/Library",
        "alt": "Library"
    },
    {
        "id": 4,
        "ImgURL": PreviewCard,
        "title": "Card Component",
        "tech": ["HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Project-preview-card-component/",
        "link": "https://github.com/Raymacmillan/Project-preview-card-component",
        "alt": "Preview-Card"
    },
    {
        "id": 5,
        "ImgURL": Card,
        "title": "Interactive Card Form",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Interactive-Card-Details-Form/",
        "link": "https://github.com/Raymacmillan/Interactive-Card-Details-Form",
        "alt": "Card"
    },
    {
        "id": 6,
        "ImgURL": Advice,
        "title": "Advice Generator App",
        "tech": ["JavaScript","API's","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Advice-Generator-App/",
        "link": "https://github.com/Raymacmillan/Advice-Generator-App",
        "alt": "Advice"
    },
    {
        "id": 7,
        "ImgURL": Testimonies,
        "title": "Bootcamp Testimonials Slider",
        "tech": ["JavaScript","HTML", "CSS"],
        "liveLink": "https://raymacmillan.github.io/Bootcamp-Testimonials-Slider/",
        "link": "https://github.com/Raymacmillan/Bootcamp-Testimonials-Slider",
        "alt": "Testimonies"
    },
    {
        "id": 8,
        "ImgURL": DropDown,
        "title": "Drop down Navigation Menu",
        "tech": ["JavaScript","HTML5", "CSS", "SASS/SCSS"],
        "liveLink": "https://raymacmillan.github.io/Drop-down-Navigation-Menu/",
        "link": "https://github.com/Raymacmillan/Drop-down-Navigation-Menu",
        "alt": "DropDown"
    },
    {
        "id": 10,
        "ImgURL": Pricing,
        "title": "Interactive Pricing Component",
        "tech": ["JavaScript","HTML5", "CSS", "SASS/SCSS"],
        "liveLink": "https://raymacmillan.github.io/Interactive-pricing-component/",
        "link": "https://github.com/Raymacmillan/Interactive-pricing-component",
        "alt": "Pricing"
    },
    {
        "id": 12,
        "ImgURL": Temperature,
        "title": "Temperature Converter",
        "tech": ["Java"],
        "liveLink": "https://github.com/Raymacmillan/Temperature-Converter",
        "link": "https://github.com/Raymacmillan/Temperature-Converter",
        "alt": "Temperature"
    },
    {
        "id": 14,
        "ImgURL": Interactive,
        "title": "Interactive Rating Component",
        "tech": ["JavaScript","HTML5", "CSS", "SASS/SCSS"],
        "liveLink": "https://raymacmillan.github.io/Interactive-Rating-Component/",
        "link": "https://github.com/Raymacmillan/Interactive-Rating-Component",
        "alt": "Interactive"
    },
];

function Projects({id, ref}) {
    return (
        <>
            <HeaderContainer>
            <Header>Dive into my domain</Header>
            <Source href="https://github.com/Raymacmillan?tab=repositories">
                <GithubImg src={GitHubLogo} alt="GitHubLogo"/>
            </Source>
            </HeaderContainer>
            <MyProjects>
                {codingProjects.map((project) => (
                    <Project key={project.id} href={project.liveLink} linkRef={project.link} ImgURL={project.ImgURL} title={project.title} tech={project.tech} alt={project.alt}></Project>
                ))}
            </MyProjects>
        </>
    )
};

export default Projects;