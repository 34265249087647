import React, { useState, useEffect, useRef } from "react";
import { Container, SubHeader, ImageContainer, Image, Buttons, PrevButton, NextButton } from "./ExpertiseStyles";

function MyExpertise({config, id, ref}) {
    const [imageIndex, setImageIndex] = useState(0);
    const imageRef = useRef(null);

    const next = () => {
        setImageIndex(state => (state+=1));
        if(imageIndex == config.length - 1)setImageIndex(0);
    }

    const prev = () => {
        setImageIndex(state => state-=1);
        if(imageIndex==0)setImageIndex(config.length - 1)
    }

    useEffect(() => {
        const intervalID = setInterval(next,5000);

        return () => clearInterval(intervalID);
    }, [next])

    return (
        <Container>
            <SubHeader>EXPERTISE</SubHeader>
            <ImageContainer>
                {config && config.length > 0 && (
            <Image ref={imageRef} src={config[imageIndex].src} alt={config[imageIndex].alt}/>)}
            <Buttons>
            <PrevButton onClick={prev} />
            <NextButton onClick={next} />
            </Buttons>
            <p>Click on the sides to Navigate expertise</p>
            </ImageContainer>
        </Container>
    )
}

export default MyExpertise;